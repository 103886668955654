<template>
	<v-container fluid class="authorization authorization--login pa-0">
		<v-row no-gutters>
			<v-col cols="12" md="6" lg="4">
				<div class="authorization__form form--login d-flex align-center">
					<div class="form__content my-auto">
						<div class="form__content--logo">
							<v-row>
								<v-col cols="12">
									<router-link
										:to="{name: 'Login'}"
									>
										<v-img
											class=""
											max-width="243"
											src="/img/logo_dark.png"
										/>
									</router-link>
								</v-col>
							</v-row>
						</div>

						<v-form
							ref="form"
							lazy-validation
						>
							<div class="form__content--form mt-4 pa-6">
								<v-row>
									<v-col cols="12">
										<h1 class="content__title">Change password</h1>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="New password*"
											:type="showNewPassword ? 'text' : 'password'"
											:append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="showNewPassword = !showNewPassword"
											v-model="newPassword"
											outlined
											required
											autocomplete="off"
											dense
											hide-details="auto"
											:rules="passwordRules"
										/>
									</v-col>
									<v-col cols="12">
										<v-text-field
											label="Confirm new password*"
											:type="showConfirmNewPassword ? 'text' : 'password'"
											:append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="showConfirmNewPassword = !showConfirmNewPassword"
											v-model="confirmNewPassword"
											outlined
											required
											autocomplete="off"
											dense
											hide-details="auto"
											:rules="confirmPasswordRules"
										/>
									</v-col>
									<v-col cols="12" xs="12" sm="12">
										<v-btn
											block
											x-large
											color="primary"
											:loading="loadingSave"
											@click="update"
										>
											CHANGE PASSWORD
										</v-btn>
									</v-col>
									<v-col cols="12" xs="12" sm="6">
										<v-btn
											block
											outlined
											color="secondary"
											:to="{name: 'Login'}"
										>
											BACK
										</v-btn>
									</v-col>
								</v-row>
							</div>
						</v-form>
					</div>
				</div>
			</v-col>
			<v-col cols="12" md="6" lg="8" class="hidden-sm-and-down">
				<block-carousel/>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import {validationRules} from '@/helpers/validationRules'
import BlockCarousel from "@/views/auth/Block/BlockCarousel";

export default {
	name: 'ChangeForgotPassword',
	components: {BlockCarousel},
	computed: {
		confirmPasswordRules() {
			return [
				v => !!v || 'Field is required!',
				v => v === this.newPassword || 'The password confirmation does not match.',
			];
		}

	},
	data: () => ({
		...validationRules(),

		loadingSave: false,

		showNewPassword: false,
		showConfirmNewPassword: false,

		newPassword: '',
		confirmNewPassword: '',

		passwordRules: [
			v => !!v || 'Field is required!',
			v => (v && v.length >= 6) || 'Please enter a longer password. Minimum length is 6 characters.',
			v => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@$!%*#?&]{6,}/.test(v) || 'The password should includes numbers, capital letters, and lower-case letters!',
		],
	}),
	methods: {
		update() {
			if (!this.$refs.form.validate()) {
				return;
			}
			this.loadingSave = true;
			this.$store.dispatch('RESTORE_PASSWORD_STORE/updateForgottenPassword', {
				password: this.newPassword,
				token: this.$route.query.token,
			})
				.then((resp) => {
					this.loadingSave = false
					if (resp.data.success) {
						this.$router.push({name: 'Login'})
						this.dialog = false;
					}
				})
				.catch(err => {
					console.error(err)
					this.loadingSave = false
				});
		},
	},
	created() {
		if (!this.$route.query.token) {
			this.$router.push({name: 'Login'})
		}
	},
};
</script>
